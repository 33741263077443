import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import SendSheetScreen from "../screens/SendSheet/SendSheetScreen";
import AskForCobroScreen from "../screens/SendSheet/AskForCobroScreen";

const SendSheetStack = createStackNavigator();

function SendSheetStackScreen({route, navigation }) {
  const { date } = route.params;

  return (
    <SendSheetStack.Navigator
      initialRouteName={"SendSheet"}
      screenOptions={{
        headerShown: false,
      }}
    >
      <SendSheetStack.Screen name="SendSheet" component={SendSheetScreen} initialParams={{ date: date }} />
      <SendSheetStack.Screen name="AskForCobro" component={AskForCobroScreen} />
    </SendSheetStack.Navigator>
  );
}

export default SendSheetStackScreen;