import 'react-native-gesture-handler';
import Main from './src/components/Main';
import React from 'react';
import {NavigationContainer} from '@react-navigation/native';

export default function App() {
    return (
        <NavigationContainer>
            <Main/>
        </NavigationContainer>
    );
}
