import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import ProjectSelect from "../screens/Others/RetenToProject";
import OthersScreen from "../screens/OtherScreen";
import SearchDay from "../screens/OtherDayScreen/SearchDay";
import SendSheetStackScreen from "./SendSheetStack"
import ResultDayStack from "../screens/OtherDayScreen/ResultDayStack";
import ConfirmDeleteScreen from "../screens/TimeScreens/ViewEditScreens/ConfirmDeleteScreen";
const OthersStack = createStackNavigator();

function OthersStackScreen() {
  return (
    <OthersStack.Navigator initialRouteName="OtrosHome">
      <OthersStack.Screen options={{title: "Otros"}} name="OtrosHome" component={OthersScreen} />
      <OthersStack.Screen options={{title: "Seleccion Proyecto"}} name="RetenProyecto" component={ProjectSelect} />
      <OthersStack.Screen options={{title: "Buscar Partes de Otros Días"}} name="SearchDay" component={SearchDay} />
      <OthersStack.Screen options={{title: "Resultado", headerShown: false}} name="ResultDay" component={ResultDayStack} />
      <OthersStack.Screen options={{title: "Enviar Parte"}} name="SendSheetStack" component={SendSheetStackScreen} />
      <OthersStack.Screen
        options={{ title: "Eliminar Parte" }}
        name="deleteTime"
        component={ConfirmDeleteScreen}
      />
    </OthersStack.Navigator>
  );
}

export default OthersStackScreen;