import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Touchable,
  TouchableOpacity,
} from "react-native";
import {
  Stack,
  TextInput,
  IconButton,
  Divider,
  Button,
  Switch,
} from "@react-native-material/core";
import { SelectList } from "react-native-dropdown-select-list";
import { TimePickerModal } from "react-native-paper-dates";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { getProjectList, getRetenList } from "../../api/projectApi";
import styles from "../TimeScreens/AddTimeScreenStyles";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CalendarPicker from "react-native-calendar-picker";
import { ScrollView } from "react-native-gesture-handler";
import { createIncidence, getIncidenceType } from "../../api/IncidenceApi";
const AddIncidenceScreen = ({ navigation }) => {
  const [porDias, setPorDias] = useState(false);
  const [selectedType, setTypeSelected] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [totalTime, setTotalTime] = useState({ hours: 0, minutes: 0 });
  const [errors, setErrors] = useState({});
  const [incidenceTypes, setIncidenceTypes] = useState([]);
  const [description, setDescription] = useState("");
  const [selectedTimePicker, setSelectedTimePicker] = useState("");

  const [visibleTotal, setVisibleTotal] = useState(false);
  const [visibleStart, setVisibleStart] = useState(false);

  const [loading, setLoading] = useState(false);


  const onDateChange = (date, type) => {
    //function to handle the date change
    if (type === "END_DATE") {
      setSelectedEndDate(date);
    } else {
      setSelectedEndDate(null);
      setSelectedStartDate(date);
    }
  };

  const handleAddTime = () => {
    setLoading(true);
    console.log("add time");
    AsyncStorage.getItem("token").then((value) => {
      if (value !== null) {
        createIncidence(
          value,
          selectedType,
          selectedStartDate,
          selectedEndDate,
          totalTime,
          description
        ).then((response) => {
          console.log(response);
          if (response.result.message == "ok") {
            navigation.navigate("Inicio");
          } else {
            setErrors(response.result.data);
          }
        });
      }
    }
    );
  };

  const getTypeIncidence = async () => {
    AsyncStorage.getItem("token").then((token) => {
      getIncidenceType(token).then((response) => {
        setIncidenceTypes(response.result.data);
      });
    });
  };

  const onDismissTotal = React.useCallback(() => {
    setVisibleTotal(false);
  }, [setVisibleTotal]);

  const onConfirmTotal = React.useCallback(
    ({ hours, minutes }) => {
      console.log(selectedTimePicker);
      setTotalTime({ hours, minutes });
      setVisibleTotal(false);
    },
    [setVisibleTotal]
  );

  useEffect(() => {
    getTypeIncidence();
  }, []);

  return (
    <>
      <ScrollView>
        <View style={styles.form}>
          <Text style={styles.formTitle}>Añadir</Text>
          <Text style={styles.subtitle}>
            Rellena los campos para añadir una incidencia
          </Text>

          <View style={styles.inputGroup}>
            <Text style={styles.text}>Tipo de incidencia:</Text>
            <View style={styles.divider} />
            <View style={{ flexGrow: 1 }}>
              <SelectList
                setSelected={(val) => setTypeSelected(val)}
                data={incidenceTypes}
                save="value"
                label="Selecciona un tipo:"
                placeholder="Selecciona un tipo"
                searchPlaceholder="Buscar Incidencia"
                notFoundText="No se han encontrado resultados"
                boxStyles={{ borderRadius: 3, borderWidth: 2 }}
                dropdownStyles={{
                  borderRadius: 3,
                  borderWidth: 0.5,
                  borderTopWidth: 0,
                  marginTop: 0,
                }}
              />
            </View>
          </View>
          {errors.project && <Text style={styles.error}>{errors.project}</Text>}
          <Divider />

          <View style={styles.inputGroup}>
            <Text style={styles.text}>Descripcion:</Text>
            <View style={styles.divider} />
            <TextInput
              color="green"
              variant="standard"
              style={{ flexGrow: 1 }}
              onChangeText={(text) => setDescription(text)}
            />
          </View>
          {errors.description && (
            <Text style={styles.error}>{errors.description}</Text>
          )}
          <Divider />
          {!porDias ? (
            <>
              <>
                <Text style={{ marginVertical: 10 }}>Selecciona el dia:</Text>
                <CalendarPicker
                  startFromMonday={true}
                  allowRangeSelection={false}
                  minDate={new Date(2018, 1, 1)}
                  maxDate={new Date(2050, 6, 3)}
                  weekdays={["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"]}
                  months={[
                    "Enero",
                    "Febero",
                    "Marzo",
                    "Abril",
                    "Mayo",
                    "Junio",
                    "Julio",
                    "Augosto",
                    "Septiembre",
                    "Octubre",
                    "Noviembre",
                    "Diciembre",
                  ]}
                  previousTitle="Anterior"
                  nextTitle="Siguiente"
                  todayBackgroundColor="#e6ffe6"
                  selectedDayColor="#66ff33"
                  selectedDayTextColor="#000000"
                  scaleFactor={400}
                  textStyle={{
                    fontFamily: "Cochin",
                    color: "#000000",
                  }}
                  onDateChange={onDateChange}
                />
              </>
              <View style={styles.inputGroup}>
                <Text style={styles.text}>Horas:</Text>
                <View style={styles.divider} />
                <TouchableOpacity
                  style={{ flexGrow: 1 }}
                  onPress={() => setVisibleTotal(true)}
                >
                  <TextInput
                    color="green"
                    variant="standard"
                    style={{ flexGrow: 1 }}
                    value={
                      totalTime.hours +
                      (totalTime.hours === 0 ? "0" : "") +
                      ":" +
                      totalTime.minutes +
                      (totalTime.minutes === 0 ? "0" : "")
                    }
                  />
                </TouchableOpacity>
              </View>
              {errors.totalTime && (
                <Text style={styles.error}>{errors.totalTime}</Text>
              )}
              <Divider />
            </>
          ) : (
            <>
              <Text style={{ marginVertical: 10 }}>Selecciona el dia:</Text>
              <CalendarPicker
                startFromMonday={true}
                allowRangeSelection={true}
                minDate={new Date(2018, 1, 1)}
                maxDate={new Date(2050, 6, 3)}
                weekdays={["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"]}
                months={[
                  "Enero",
                  "Febero",
                  "Marzo",
                  "Abril",
                  "Mayo",
                  "Junio",
                  "Julio",
                  "Augosto",
                  "Septiembre",
                  "Octubre",
                  "Noviembre",
                  "Diciembre",
                ]}
                previousTitle="Anterior"
                nextTitle="Siguiente"
                todayBackgroundColor="#e6ffe6"
                selectedDayColor="#66ff33"
                selectedDayTextColor="#000000"
                scaleFactor={400}
                textStyle={{
                  fontFamily: "Cochin",
                  color: "#000000",
                }}
                onDateChange={onDateChange}
              />
            </>

          )}

          <View style={styles.inputGroup}>
            <Text style={styles.text}>Por dias:</Text>
            <Switch
              style={{ alignSelf: "center" }}
              value={porDias}
              onValueChange={() => setPorDias(!porDias)}
            />
          </View>

          <TimePickerModal
            visible={visibleTotal}
            onDismiss={onDismissTotal}
            onConfirm={onConfirmTotal}
            hours={0}
            minutes={0}
            label="Selecciona Hora"
            cancelLabel="Cancelar"
            confirmLabel="Confirmar"
          />
        </View>

        <View style={styles.footer}>
          <Button
            style={styles.btn}
            color="green"
            title="CONFIRMAR"
            titleStyle={styles.footerText}
            onPress={() => {
              handleAddTime();
            }}
            leading={(props) => <Icon name="plus" {...props} />}
            loading={loading}
          />
        </View>
      </ScrollView>
    </>
  );
};

export default AddIncidenceScreen;
