import React, { useEffect, useState } from "react";
import {
    View,
    Text,
    StyleSheet,
    Touchable,
    TouchableOpacity,
} from "react-native";
import {
    Stack,
    TextInput,
    IconButton,
    Divider,
    Button,
    Switch,
} from "@react-native-material/core";
import { SelectList } from "react-native-dropdown-select-list";
import { TimePickerModal } from "react-native-paper-dates";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { getProjectList, getRetenList } from "../../api/projectApi";
import styles from "../TimeScreens/AddTimeScreenStyles";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CalendarPicker from "react-native-calendar-picker";
import { ScrollView } from "react-native-gesture-handler";
import { createIncidence, createTodayIncidence, getIncidenceType } from "../../api/IncidenceApi";

const AddIncidenceScreen = ({ navigation }) => {
    const [selectedType, setTypeSelected] = useState("");
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [totalTime, setTotalTime] = useState({ hours: 0, minutes: 0 });
    const [errors, setErrors] = useState({});
    const [incidenceTypes, setIncidenceTypes] = useState([]);
    const [description, setDescription] = useState("");
    const [amount, setAmount] = useState(0);
    const [qunatity, setQunatity] = useState(0.00);
    const [loading, setLoading] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const [selectedProject, setProjectSelected] = useState("");


    const handleAddTime = () => {
        setLoading(true);
        AsyncStorage.getItem("token").then((value) => {
            if (value !== null) {
                createTodayIncidence(
                    value,
                    selectedType,
                    qunatity,
                    amount,
                    description,
                    selectedProject
                ).then((response) => {
                    console.log(response);
                    if (response.result.message == "ok") {
                        // navigation.navigate("Inicio");
                        navigation.goBack()
                    } else {
                        setErrors(response.result.data);
                    }
                });
            }
        }
        );
    };

    const getProjecList = async () => {
        AsyncStorage.getItem("token").then((token) => {
            getProjectList(token).then((response) => {
                setProjectList(response.result.data);
            });
        });
    };

    const getTypeIncidence = async () => {
        AsyncStorage.getItem("token").then((token) => {
            getIncidenceType(token).then((response) => {
                setIncidenceTypes(response.result.data);
            });
        }); 
    };

    useEffect(() => {
        getTypeIncidence();
        getProjecList();
    }, []);

    return (
        <>
            <ScrollView>
                <View style={styles.form}>
                    <Text style={styles.formTitle}>Añadir</Text>
                    <Text style={styles.subtitle}>
                        Rellena los campos para añadir una incidencia
                    </Text>

                    <View style={styles.inputGroup}>
                        <Text style={styles.text}>Tipo de incidencia:</Text>
                        <View style={styles.divider} />
                        <View style={{ flexGrow: 1 }}>
                            <SelectList
                                setSelected={(val) => setTypeSelected(val)}
                                data={incidenceTypes}
                                save="value"
                                label="Selecciona un tipo:"
                                placeholder="Selecciona un tipo"
                                searchPlaceholder="Buscar Incidencia"
                                notFoundText="No se han encontrado resultados"
                                boxStyles={{ borderRadius: 3, borderWidth: 2 }}
                                dropdownStyles={{
                                    borderRadius: 3,
                                    borderWidth: 0.5,
                                    borderTopWidth: 0,
                                    marginTop: 0,
                                }}
                            />
                        </View>
                    </View>

                    <View style={[styles.inputGroup]}>
                        <Text style={styles.text}>Proyecto:</Text>
                        <View style={styles.divider} />
                        <View style={{ flexGrow: 1 }}>
                            <SelectList
                                setSelected={(val) => setProjectSelected(val)}
                                data={projectList}
                                save="value"
                                label="Selecciona un proyecto:"
                                placeholder="Selecciona un proyecto"
                                searchPlaceholder="Buscar proyecto"
                                notFoundText="No se han encontrado resultados"
                                boxStyles={{ borderRadius: 3, borderWidth: 2 }}
                                dropdownStyles={{
                                    borderRadius: 3,
                                    borderWidth: 0.5,
                                    borderTopWidth: 0,
                                    marginTop: 0,
                                }}
                            />
                        </View>

                    </View>

                    {errors.project && <Text style={styles.error}>{errors.project}</Text>}
                    <Divider />

                    <View style={styles.inputGroup}>
                        <Text style={styles.text}>Descripcion:</Text>
                        <View style={styles.divider} />
                        <TextInput
                            color="green"
                            variant="standard"
                            style={{ flexGrow: 1 }}
                            onChangeText={(text) => setDescription(text)}
                        />
                    </View>
                    {errors.description && (
                        <Text style={styles.error}>{errors.description}</Text>
                    )}
                    <Divider />

                    {/* <View style={styles.inputGroup}>
                        <Text style={styles.text}>Cantidad:</Text>
                        <View style={styles.divider} />
                        <TextInput
                            color="green"
                            variant="number-pad"
                            style={{ flexGrow: 1 }}
                            onChangeText={(text) => setQunatity(parseInt(text))}
                        />
                    </View>
                    <View style={styles.inputGroup}>
                        <Text style={styles.text}>Importe:</Text>
                        <View style={styles.divider} />
                        <TextInput
                            color="green"
                            variant="number-pad"
                            style={{ flexGrow: 1 }}
                            onChangeText={(text) => setAmount(parseFloat(text))}
                        />
                    </View> */}
                </View>

                <View style={styles.footer}>
                    <Button
                        style={styles.btn}
                        color="green"
                        title="CONFIRMAR"
                        titleStyle={styles.footerText}
                        onPress={() => {
                            handleAddTime();
                        }}
                        leading={(props) => <Icon name="plus" {...props} />}
                        loading={loading}
                    />
                </View>
            </ScrollView>
        </>
    );
};

export default AddIncidenceScreen;
