import React, { useEffect } from "react";
import { View, Text, StyleSheet } from "react-native";
import { Stack, ActivityIndicator } from "@react-native-material/core";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function Splash({ navigation}){
    

    useEffect(() => {
        checkToken();
    }, []);


    const checkToken = async () => {
        try {
            const token = await AsyncStorage.getItem('token');
            if (token) {
                navigation.replace("Navigation");
            }else{
                navigation.replace("Login");
            }
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <Stack fill center spacing={4}>
            <ActivityIndicator size="large" color="#00ff00" />
        </Stack>
    );
}