import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Login from "../screens/LoginScreen";
import Navigation from "./Navigation";
import Splash from "../screens/Splash";
import SendSheetStackScreen from "./SendSheetStack";
export default function LoginManagement() {
    const Stack = createStackNavigator();

    return (
        <Stack.Navigator initialRouteName="Splash" screenOptions={{ headerShown: false }}>
            <Stack.Screen name="Splash" component={Splash} />
            <Stack.Screen name="Login" component={Login} />
            <Stack.Screen name="SendSheetStack" component={SendSheetStackScreen} />
            <Stack.Screen name="Navigation" component={Navigation} />
        </Stack.Navigator>
    );
}