import React from "react";
import { ListItem } from "@react-native-material/core";
import { getProjectList } from "../../api/projectApi";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { altaRetenCall } from "../../api/timesheetApi";
const ProjectSelect = ({navigation}) => {
  const [projectList, setProjectList] = React.useState([]);
    React.useEffect(() => {
        getProjectListCall();
    }, []);
    const getProjectListCall = async (token) => {
        if (token === undefined) {
            AsyncStorage.getItem("token").then((value) => {
                if (value !== null) {
                    getProjectListCall(value);
                }
            });
        } else {
            const response = await getProjectList(token);
            console.log(response);
            setProjectList(response.result.data);
        }
    };

    const altaRetenApi = async (token, projectId) => {
        if (token === undefined) {
            AsyncStorage.getItem("token").then((value) => {
                if (value !== null) {
                    console.log(value);
                    altaRetenApi(value, projectId);
                }
            });
        } else {
            const response = await altaRetenCall(token, false, projectId);
            console.log(response);
            navigation.goBack();
        }
    }

  return (
    <>
        {projectList.map((project) => (
            <ListItem
                key={project.key}
                title={project.value}
                onPress={() => altaRetenApi(undefined, project.key)}
                style={{padding: 10}}
            />
        ))}
    </>
  );
};

export default ProjectSelect;
