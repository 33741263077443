import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, Alert } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Stack, Button } from "@react-native-material/core";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { mdiReceiptTextSend, mdiReceiptTextArrowLeftOutline } from "@mdi/js";
import { createTimesheetSheet } from "../../api/timesheetApi";
export default function SendSheetScreen({route, navigation }) {
  const [horasCalendario, setHorasCalendario] = useState(0);
  const [horasTrabajadas, setHorasTrabajadas] = useState(0);
  const { date } = route.params;

  useEffect(() => {
    AsyncStorage.getItem("horas_calendario").then((horas) => {
      setHorasCalendario(horas);
    });
    AsyncStorage.getItem("horas_trabajadas").then((horas) => {
      setHorasTrabajadas(horas);
    });
  }, []);

  const handleContinue = () => {
    if (Math.floor(horasTrabajadas) > Math.floor(horasCalendario)) {
      navigation.navigate("AskForCobro" , {date: date});
    } else {
      sendSheets(false);
    }
  };

  const sendSheets = (cobrarHoras) => {
    AsyncStorage.getItem("token").then((token) => {
      createTimesheetSheet(token, cobrarHoras, date).then(
        (response) => {
          navigation.goBack();
        }
      );
    });
  };

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      position: "relative",
      justifyContent: "center",
      alignItems: "center",
      padding: 20,
      backgroundColor: "#fff",
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
      textAlign: "center",
    },
    subtitle: {
      fontSize: 16,
      marginBottom: 40,
      textAlign: "center",
    },
    button: {
      flex: 1,
      fontSize: 20,
      fontWeight: "bold",
    },
  });

  return (
    <View style={styles.container}>
      <Icon
        name="file-send-outline"
        style={{ marginBottom: 10 }}
        size={150}
        color="green"
      />
      <View>
        <Text style={styles.title}>
          ¿Estás seguro de que quieres enviar los partes?
        </Text>
        <Text style={styles.subtitle}>
          Una vez enviados no podrás modificarlos.
        </Text>
        <View style={{ flexDirection: "row" }}>
          <Button
            style={styles.button}
            title="Atrás"
            onPress={() => navigation.goBack()}
          />
          <Button
            titleStyle={{ fontSize: 15, fontWeight: "600" }}
            style={styles.button}
            title="Continuar"
            color="green"
            onPress={handleContinue}
          />
        </View>
      </View>
    </View>
  );
}
