import ApiManager from "./ApiManager";

export const getTodayTimesheets = async (token) => {
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/todaytimesheet',
            data: {
                "params": {token}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const getAnyDayTimesheets = async (token, day) => {
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/anydaytimesheet',
            data: {
                "params": {token, day}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}