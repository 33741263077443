import React, {useEffect} from "react";
import {useFocusEffect} from "@react-navigation/native";
import {createStackNavigator} from "@react-navigation/stack";
import HomeScreen from "../screens/HomeScreen";
import AddTimeScreen from "../screens/TimeScreens/AddTimeScreen";
import ViewEditScreen from "../screens/TimeScreens/ViewEditScreens/ViewEditScreen";
import {Stack, IconButton} from "@react-native-material/core";
import Icon from "@mdi/react";
import {mdiReceiptTextSend, mdiReceiptTextArrowLeftOutline, mdiFlagPlus} from "@mdi/js";
import {createTimesheetSheet, draftTimesheetSheet} from "../api/timesheetApi";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {View, Button, Text} from "react-native";
import {Switch} from "@react-native-material/core";
import {Badge} from "react-native-paper";
import {checkReten} from "../api/timesheetApi";
import ConfirmDeleteScreen from "../screens/TimeScreens/ViewEditScreens/ConfirmDeleteScreen";
import AddIncidenceScreen from "../screens/IndicenceScreen/AddIncidenceScreen";
import AddIncidenceTodayScreen from "../screens/IndicenceScreen/AddIncidenceTodayScreen";
import AddCurDayLeaveScreen from "../screens/LeaveScreen/addCurDayLeaveScreen";
import AltaRetenScreen from "../screens/AltaRetenScreen/AltaRetenScreen";
import TakePhoto from "../screens/LeaveScreen/takePhoto";
const HomeStack = createStackNavigator();

function HomeStackScreen({navigation}) {
    const [cobrarHoras, setCobrarHoras] = React.useState(false);
    const [preguntarHoras, setPreguntarHoras] = React.useState(false);
    const [horasCalendario, setHorasCalendario] = React.useState(0);
    const [horasTrabajadas, setHorasTrabajadas] = React.useState(0);
    const [enReten, setEnReten] = React.useState(false);
    const [userName, setUserName] = React.useState("")
    const moment = require('moment');


    useFocusEffect(
        React.useCallback(() => {
            checkRetenCall();
        }, [])
    );

    const checkRetenCall = async (token) => {
        if (token === undefined) {
            AsyncStorage.getItem("token").then((value) => {
                if (value !== null) {
                    checkRetenCall(value);
                }
            });
        } else {
            const response = await checkReten(token);
            console.log(response.result.message);
            setEnReten(response.result.message == "false" ? false : true);
            getUsername(token);
        }
    };

    const getUsername = async (token) => {
        if (token === undefined) {
            AsyncStorage.getItem("token").then((value) => {
                if (value !== null) {
                    checkRetenCall(value);
                }
            });
        } else {
            const response = await AsyncStorage.getItem("username").then((username) => {
                setUserName(username)
            })
        }
    }

    const ConfirmableButton = () => {
        const handlePress = () => {
            navigation.navigate("SendSheetStack", {date: moment()});
        }

        return (
            <IconButton
                onPress={handlePress}
                icon={(props) => <Icon path={mdiReceiptTextSend} size={1}/>}
            />
        );
    };


    return (
        <HomeStack.Navigator
            initialRouteName="Inicio"
            screenOptions={{cardStyle: {backgroundColor: "#F7F7F7"}}}
        >
            <HomeStack.Screen
                screenOptions={{headerShown: false}}
                options={() => ({
                    headerRight: () => (
                        <View style={{flexDirection: "row"}}>
                            <Badge
                                visible={enReten}
                                style={{alignSelf: "center", marginRight: 50}}
                                size={25}
                            >
                                <Text style={{padding: 15, fontSize: 15}}>En reten</Text>
                            </Badge>

                            <IconButton
                                onPress={() => {
                                    navigation.navigate("AddIncidence");
                                }
                                }
                                icon={(props) => <Icon path={mdiFlagPlus} size={1}/>}
                            />
                            <ConfirmableButton/>

                        </View>
                    ),

                    headerTitle: () => (
                        <View>
                            <Text>
                                <p style={{padding: 0, margin: 0, fontSize: 11 }}>{userName}</p>
                                <b style={{padding: 0, margin: 0, fontSize: 17 }}>Partes de hoy</b></Text>
                        </View>
                    ),
                })}
                name="Inicio"
                component={HomeScreen}
            />
            <HomeStack.Screen
                options={{title: "Añadir Parte"}}
                name="AddTime"
                component={AddTimeScreen}
            />
            <HomeStack.Screen
                options={{title: "Ver Parte"}}
                name="ViewEdit"
                component={ViewEditScreen}
            />
            <HomeStack.Screen
                options={{title: "Añadir Incidencia"}}
                name="AddIncidence"
                component={AddIncidenceScreen}
            />
            <HomeStack.Screen
                options={{title: "Añadir Incidencia Dia Hoy"}}
                name="AddIncidenceToday"
                component={AddIncidenceTodayScreen}
            />
            <HomeStack.Screen
                options={{title: "Eliminar Parte"}}
                name="deleteTime"
                component={ConfirmDeleteScreen}
            />
            <HomeStack.Screen
                options={() => ({
                    title: "Añadir Ausencia Hoy",
                })}
                name="AddLeave"
                component={AddCurDayLeaveScreen}
            />
            <HomeStack.Screen
                options={() => ({
                    title: "Seleccionar Proyecto para reten",
                })}
                name="AltaRetenScreen"
                component={AltaRetenScreen}
            />
            <HomeStack.Screen
                options={() => ({
                    title: "Tomar Foto",
                })}
                name="TakePhoto"
                component={TakePhoto}
            />
        </HomeStack.Navigator>
    );
}

export default HomeStackScreen;
