import React, {useState} from "react";
import {useFocusEffect} from "@react-navigation/native";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import {View, Text, StyleSheet} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {getLeaves} from "../../api/leaveApi";
import {FlatList} from "react-native-gesture-handler";
import LeaveListItem from "../../components/LeaveListItem";
// import { FAB } from "@rneui/themed";
import {FAB} from 'react-native-paper';

const LeaveScreen = ({navigation}) => {
    const [ausencias, setAusencias] = useState([]);

    const getAusencias = async (token) => {
        if (token === undefined) {
            AsyncStorage.getItem("token").then((value) => {
                if (value !== null) {
                    getAusencias(value);
                }
            });
        } else {
            await getLeaves(token).then((response) => {
                setAusencias(response.result.data);
                console.log(response.result.data);
            });
        }
    };

    useFocusEffect(
        React.useCallback(() => {
            getAusencias();
        }, [])
    );

    return (
        <>
            <View style={{justifyContent: "center", alignItems: "center"}}>
                {ausencias.length == 0 && (
                    <View style={{margin: 20}}>
                        <Text
                            style={{
                                color: "blue",
                                textAlign: "center",
                                fontWeight: "500",
                                fontSize: 20,
                            }}
                        >
                            No hay Ausencias
                        </Text>
                    </View>
                )}

                <FlatList
                    data={ausencias}
                    renderItem={({item}) => (
                        <LeaveListItem navigation={navigation} props={item}/>
                    )}
                    keyExtractor={(item) => item.id.toString()}
                />
            </View>
            <FAB
                style={styles.fab}
                icon={"plus"}
                onPress={() => navigation.navigate("AddLeave")}
            />
        </>
    );
};
const styles = StyleSheet.create({
    fab: {
        position: 'absolute', margin: 16, right: 0, bottom: 0, backgroundColor: "green",
    },
});
export default LeaveScreen;
