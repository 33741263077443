import React, { useState } from "react";
import {View, Text, StyleSheet, Image} from "react-native";
import { TextInput, Button } from "@react-native-material/core";
import { userLogin } from "../api/userLogin";
import { checkApi } from "../api/checkApi";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function LoginScreen({ navigation}) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false); //error de login [true, false
    const [loading, setLoading] = useState(false);

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: "#fff",
            justifyContent: "center",
            gap: 20,
        },
        logo: {
            width: "100%",
            height: 150,
            marginBottom: 20,
        },
    });

    const handleApiLogin =  () => {
        setLoading(true);
        //peticion fetch a la api, ruta localhost:8015/api/check
        userLogin({username, password}).then((response,) => {
            console.log(response);
            if(response.result.message == "success"){
                AsyncStorage.setItem("token", response.result.token).then(() => {
                    AsyncStorage.setItem("username", response.result.username).then(() => {
                        setLoading(false);
                        setError(false);
                        navigation.replace("Navigation");
                    });
                });
            }
            else if(response.result.message == "error"){
                setLoading(false);
                setError(true);
            }            
        }
        );

    };
    

    return (
        <View style={styles.container}>
            <Image source={require("../../assets/splash.png")} style={styles.logo} />
            {error && <Text style={{ color: "red", textAlign: "center" }}>Usuario o contraseña incorrectos</Text>}
            <TextInput color="green" label="Usuario" style={{ marginHorizontal: 30 }} 
            onChangeText={(text) => setUsername(text)}
            />
            <TextInput color="green" label="Contraseña" style={{ marginHorizontal: 30 }}
            secureTextEntry={true}
            onChangeText={(text) => setPassword(text) }
             />
            <Button
                style={{ marginHorizontal: 30 }}
                title="Acceder"
                loading={loading}
                color="green"
                onPress={() => handleApiLogin()}
            />
        </View>
    );

   
}