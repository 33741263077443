import React, {useEffect, useState} from "react";
import {useFocusEffect} from "@react-navigation/native";
import {View, StyleSheet} from "react-native";
import {checkReten, altaRetenCall} from "../api/timesheetApi";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {Stack, Button} from "@react-native-material/core";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";


const OthersScreen = ({navigation}) => {
    const [altaReten, setAltaReten] = useState(false);

    useFocusEffect(
        React.useCallback(() => {
            checkRetenCall();
        }, [])
    );

    const checkRetenCall = async (token) => {
        if (token === undefined) {
            AsyncStorage.getItem("token").then((value) => {
                if (value !== null) {
                    console.log(value);
                    checkRetenCall(value);
                }
            });
        } else {
            const response = await checkReten(token);
            console.log(response.result.message);
            setAltaReten(response.result.message == "false" ? true : false);
        }
    }

    const altaRetenApi = async (token) => {
        if (token === undefined) {
            AsyncStorage.getItem("token").then((value) => {
                if (value !== null) {
                    console.log(value);
                    altaRetenApi(value);
                }
            });
        } else {
            const response = await altaRetenCall(token, false, false);
            console.log(response);
            navigation.goBack();
        }
    }

    const logout = async (token) => {
        if (token === undefined) {
            AsyncStorage.getItem("token").then((value) => {
                if (value !== null) {
                    logout(value)
                }
            });
        } else {
            AsyncStorage.clear().then(() => {
                navigation.replace("Login")
            })
        }
    }


    return (
        <View
            style={{flex: 1, fontSize: 30, textAlign: "center", justifyContent: "space-evenly", alignItems: "center"}}>
            <Button color="green" title={'Partes de otros días'} onPress={() => navigation.navigate("SearchDay")}
                    trailing={props => <Icon name="calendar-search" {...props} />}/>
{/*             <Button disabled={!altaReten} color="orange" title={!altaReten ? 'Reten dado de alta' : 'Alta Reten'}
                    onPress={() => altaRetenApi()} trailing={props => <Icon name="progress-clock" {...props} />}/> */}
            <Button color="grey" title="Cerrar Sesion" onPress={() => logout()}
                    trailing={props => <Icon name="logout" {...props} />} />
        </View>
    );
}


export default OthersScreen;