import React, {useEffect, useState} from "react";
import { NavigationContainer } from "@react-navigation/native";
import MyTabs from "./Tabs";
import AsyncStorage from '@react-native-async-storage/async-storage';



export default function Navigation() {

    return (
        <MyTabs />
    );
}


//TODO: NECESITO TENER LA RUTA DE LOGIN Y LA RUTA DE HOME EN UN STACK NAVIGATION.