import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { Stack, Button } from "@react-native-material/core";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { mdiReceiptTextSend, mdiReceiptTextArrowLeftOutline } from "@mdi/js";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { createTimesheetSheet } from "../../api/timesheetApi";
export default function AskForCobroScreen({route, navigation }) {
  const { date } = route.params;
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      position: "relative",
      justifyContent: "center",
      alignItems: "center",
      padding: 20,
      backgroundColor: "#fff",
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
      textAlign: "center",
    },
    subtitle: {
      fontSize: 16,
      marginBottom: 40,
      textAlign: "center",
    },
    button: {
      flex: 1,
      fontSize: 20,
      fontWeight: "bold",
    },
  });

  const sendSheets = (cobrarHoras) => {
    AsyncStorage.getItem("token").then((token) => {
      createTimesheetSheet(
        token,
        cobrarHoras,
        date,
      ).then((response) => {
        navigation.goBack();
        navigation.goBack();
      });
    });
    
  };

  return (
    <View style={styles.container}>
      <Icon
        name="clock-alert-outline"
        style={{ marginBottom: 10 }}
        size={150}
        color="green"
      />
      <View>
        <Text style={styles.title}>
          Hemos detectado que tu jornada supera las horas de tu calendario.
        </Text>
        <Text style={styles.subtitle}>¿Quieres disfrutar las horas festivas?</Text>
        <View style={{ flexDirection: "row" }}>
          <Button
            style={styles.button}
            title="Si"
            color="#CC322D"
            onPress={() => sendSheets(false)}
          />
          <Button
              style={styles.button}
              title="No"
              color="green"
              onPress={() => sendSheets(true)}
          />
        </View>
      </View>
    </View>
  );
}
