import axios from 'axios';

const API_URL = 'https://jauregui.zertek.net/api/';
//const API_URL = 'http://192.168.222.149/api/';
//const API_URL = 'http://localhost:8015/api/';

const ApiManager = axios.create({
    baseURL: API_URL,
    responseType: 'json',
})

export default ApiManager;