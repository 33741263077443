import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Touchable,
  TouchableOpacity,
} from "react-native";
import styles from "../AddTimeScreenStyles";
import { SelectList } from "react-native-dropdown-select-list";
import {
  Stack,
  TextInput,
  IconButton,
  Divider,
  Button,
  Switch,
} from "@react-native-material/core";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { TimePickerModal } from "react-native-paper-dates";
import { updateTimesheet } from "../../../api/timesheetApi";
const EditScreen = ({ item, projectList, navigation, taskTypes }) => {

  const [selectedType, setTypeSelected] = useState("");
  const [selectedProject, setProjectSelected] = useState("");
  const [selectedTask, setTaskSelected] = useState("");
  const [startTime, setStartTime] = useState({ hours: 0, minutes: 0 });
  const [totalTime, setTotalTime] = useState({ hours: 0, minutes: 0 });
  const [selectedDescription, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [itemId, setItemId] = useState(item.id);
  const [isReten, setReten] = useState(item.is_reten);

  const [selectedTimePicker, setSelectedTimePicker] = useState("");


  const [visibleTotal, setVisibleTotal] = useState(false);
  const [visibleStart, setVisibleStart] = useState(false);

  const [loading, setLoading] = useState(false);


  const handleUpdate = () => {
    setLoading(true);
    if (selectedType === "") {
      setErrors({ type: "Elige un tipo de hora" });
      setLoading(false);
      return;
    }
    if (selectedProject === "") {
      setErrors({ project: "Elige un proyecto" });
      setLoading(false);
      return;
    }
    if (selectedDescription === "") {
      setErrors({ description: "Añade una descripción" });
      setLoading(false);
      return;
    }
    if (startTime.hours === 0 && startTime.minutes === 0) {
      let currentdate = new Date();
      startTime.hours = currentdate.getHours();
      startTime.minutes = currentdate.getMinutes();
    }

    if (totalTime.hours === 0 && totalTime.minutes === 0) {
      setErrors({ totalTime: "Elige un tiempo" });
      setLoading(false);
      return;
    }

    setErrors({});


    AsyncStorage.getItem("token").then((token) => {
      updateTimesheet(
        isReten,
        token,
        itemId,
        selectedProject,
        selectedType,
        totalTime,
        selectedDescription,
        startTime,
      ).then((response) => {
        setLoading(false);
        if (response.error) {
          alert(response.error.data.message);
        }
        else if (response.result.message == "ok") {
          navigation.navigate("Inicio", { message: "Parte añadido correctamente" });

        }
      });
    }
    );


  };




  const onDismiss = React.useCallback(() => {
    setVisibleStart(false);
  }, [setVisibleStart]);

  const onConfirm = React.useCallback(
    ({ hours, minutes }) => {
      setStartTime({ hours, minutes });
      setVisibleStart(false);
    },
    [setVisibleStart]
  );


  const onDismissTotal = React.useCallback(() => {
    setVisibleTotal(false);
  }, [setVisibleTotal]);

  const onConfirmTotal = React.useCallback(
    ({ hours, minutes }) => {
      setTotalTime({ hours, minutes });
      setVisibleTotal(false);
    },
    [setVisibleTotal]
  );

  useEffect(() => {

    setTypeSelected(item.type.name);
    setProjectSelected(item.project_id.name);
    setDescription(item.description);
    setStartTime({ hours: (item.time_start.split(':')[0]), minutes: item.time_start.split(':')[1] });
    setTotalTime({ hours: item.unit_amount.split(':')[0], minutes: item.unit_amount.split(':')[1] });

  }, []);



  return (
    <>
      <View style={[styles.form2, ]}>
        <IconButton
          style={{alignSelf: "flex-end", backgroundColor: "red", borderRadius: 50, margin: 10}}
          onPress={() => navigation.navigate("deleteTime", {itemId: itemId})}
          icon={props => <Icon name="trash-can-outline" size={ 500} {...props} />}
          color="white"
        />
        <Text style={styles.formTitle}>Editar</Text>
        <Text style={styles.subtitle}>
          Modifica los campos que desees y pulsa en guardar
        </Text>
        <View style={styles.inputGroup}>
          <Text style={styles.text}>Tipo de hora:</Text>
          <View style={styles.divider} />
          <View style={{ flexGrow: 1 }}>
            <SelectList
              setSelected={(val) => setTypeSelected(val)}
              data={taskTypes}
              save="value"
              label="Selecciona un tipo:"
              placeholder={item.type.name}
              searchPlaceholder="Buscar tipo de parte"
              notFoundText="No se han encontrado resultados"
              boxStyles={{ borderRadius: 3, borderWidth: 2 }}
              dropdownStyles={{
                borderRadius: 3,
                borderWidth: 0.5,
                borderTopWidth: 0,
                marginTop: 0,
              }}
            />
          </View>
        </View>
        {errors.type && <Text style={styles.error}>{errors.type}</Text>}

        <Divider />
        <View style={styles.inputGroup}>
          <Text style={styles.text}>Proyecto:</Text>
          <View style={styles.divider} />
          <View style={{ flexGrow: 1 }}>
            <SelectList
              setSelected={(val) => setProjectSelected(val)}
              data={projectList}
              save="value"
              label="Selecciona un proyecto:"
              placeholder={item.project_id.name}
              searchPlaceholder="Buscar proyecto"
              notFoundText="No se han encontrado resultados"
              boxStyles={{ borderRadius: 3, borderWidth: 2 }}
              dropdownStyles={{
                borderRadius: 3,
                borderWidth: 0.5,
                borderTopWidth: 0,
                marginTop: 0,
              }}
            />
          </View>

        </View>
        {errors.project && (
          <Text style={styles.error}>{errors.project}</Text>
        )}
        <Divider />

        <View style={styles.inputGroup}>
          <Text style={styles.text}>Descripcion:</Text>
          <View style={styles.divider} />
          <TextInput
            color="green"
            variant="standard"
            style={{ flexGrow: 1 }}
            onChangeText={(text) => setDescription(text)}
            value={item.name}
          />

        </View>
        {errors.description && (
          <Text style={styles.error}>{errors.description}</Text>
        )}
        <Divider />

        <View style={styles.inputGroup}>
          <Text style={styles.text}>Hora de inicio:</Text>
          <View style={styles.divider} />
          <TouchableOpacity
            style={{ flexGrow: 1 }}
            onPress={() => setVisibleStart(true)}
          >
            <TextInput
              color="green"
              variant="standard"
              style={{ textAlign: "center" }}
              value={
                startTime.hours + ":" + startTime.minutes
              }
            />
          </TouchableOpacity>
        </View>
        {errors.startTime && (
          <Text style={styles.error}>{errors.startTime}</Text>
        )}
        <Divider />
        <View style={styles.inputGroup}>
          <Text style={styles.text}>Horas:</Text>
          <View style={styles.divider} />
          <TouchableOpacity
            style={{ flexGrow: 1 }}
            onPress={() => setVisibleTotal(true)}
          >
            <TextInput
              color="green"
              variant="standard"
              style={{ flexGrow: 1 }}
              value={
                totalTime.hours + ":" + totalTime.minutes
              }
            />
          </TouchableOpacity>

        </View>
        {errors.totalTime && (
          <Text style={styles.error}>{errors.totalTime}</Text>
        )}
        <Divider />
        <View style={[styles.inputGroup]}>
          <Text style={styles.text}>Trabajo durante reten:</Text>
          <Switch value={isReten} onValueChange={() => setReten(!isReten)} />
        </View>
        <TimePickerModal
          visible={visibleStart}
          onDismiss={onDismiss}
          onConfirm={onConfirm}
          hours={startTime.hours}
          minutes={startTime.minutes}
          label="Selecciona Hora"
          cancelLabel="Cancelar"
          confirmLabel="Confirmar"
        />

        <TimePickerModal
          visible={visibleTotal}
          onDismiss={onDismissTotal}
          onConfirm={onConfirmTotal}
          hours={totalTime.hours}
          minutes={totalTime.minutes}
          label="Selecciona Hora"
          cancelLabel="Cancelar"
          confirmLabel="Confirmar"
        />
      </View>
      <View style={styles.footer}>
        <Button
          style={styles.btn}
          color="green"
          title="GUARDAR"
          titleStyle={styles.footerText}
          onPress={() => {
            handleUpdate();
          }}
          leading={(props) => (
            <Icon
              name="pencil"
              {...props}
            />
          )}
          loading={loading}
        />
      </View>
    </>
  );

}

export default EditScreen;