import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, Alert } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Stack, Button } from "@react-native-material/core";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { deleteTimesheet } from "../../../api/timesheetApi";
export default function ConfirmDeleteScreen({route, navigation }) {
  const { itemId } = route.params;

  const handleContinue = () => {
      deleteParte();
  };

  const deleteParte = () => {
    AsyncStorage.getItem("token").then((token) => {
      deleteTimesheet(token, itemId).then(
        (response) => {
          navigation.goBack();
          navigation.goBack();
        }
      );
    });
  };

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      position: "relative",
      justifyContent: "center",
      alignItems: "center",
      padding: 20,
      backgroundColor: "#fff",
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
      textAlign: "center",
    },
    subtitle: {
      fontSize: 16,
      marginBottom: 40,
      textAlign: "center",
    },
    button: {
      flex: 1,
      fontSize: 20,
      fontWeight: "bold",
    },
  });

  return (
    <View style={styles.container}>
      <Icon
        name="trash-can-outline"
        style={{ marginBottom: 10 }}
        size={150}
        color="red"
      />
      <View>
        <Text style={styles.title}>
          ¿Estás seguro de que quieres eliminar el parte?
        </Text>
        <Text style={styles.subtitle}>
          Una vez eliminado no se podra recuperar.
        </Text>
        <View style={{ flexDirection: "row" }}>
          <Button
            style={styles.button}
            title="No"
            color="grey"
            onPress={() => navigation.goBack()}
          />
          <Button
            titleStyle={{ fontSize: 15, fontWeight: "600" }}
            style={styles.button}
            title="Si"
            color="red"
            onPress={handleContinue}
          />
        </View>
      </View>
    </View>
  );
}
