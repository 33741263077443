import React, { useEffect, useState } from "react";
import {
    View,
    Text,
    StyleSheet,
    Touchable,
    TouchableOpacity, Modal,
} from "react-native";
import useTaskTypes from "../../hooks/useTaskType";
import {
    Stack,
    TextInput,
    IconButton,
    Divider,
    Button,
    Switch,
} from "@react-native-material/core";
import { SelectList } from "react-native-dropdown-select-list";
// import {TimePickerModal} from "react-native-paper-dates";
import { TimePickerModal } from "../../../modulos/react-native-paper-dates";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { getProjectList, getRetenList } from "../../api/projectApi";
import styles from "./AddTimeScreenStyles";
import { addTimesheet } from "../../api/timesheetApi";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { ScrollView } from "react-native-gesture-handler";


const AddTimeScreen = ({ route, navigation }) => {
    const [selectedType, setTypeSelected] = useState("");
    const [selectedProject, setProjectSelected] = useState("");
    const [selectedReten, setRetenSelected] = useState("");

    const [selectedTask, setTaskSelected] = useState("");
    const [startTime, setStartTime] = useState({ hours: 0, minutes: 0 });
    const [totalTime, setTotalTime] = useState({ hours: 0, minutes: 0 });
    const [selectedDescription, setDescription] = useState("");
    const [errors, setErrors] = useState({});
    const { altaReten, fecha } = route.params;

    const [isReten, setReten] = useState(altaReten);


    const [selectedTimePicker, setSelectedTimePicker] = useState("");

    const [projectList, setProjectList] = useState([]);
    const [retenList, setRetenList] = useState([]);

    const { taskTypes } = useTaskTypes();

    const [visibleTotal, setVisibleTotal] = useState(false);
    const [visibleStart, setVisibleStart] = useState(false);

    const [loading, setLoading] = useState(false);


    const handleAddTime = () => {
        setLoading(true);
        let currentdate;
        let selectedTime
        if (fecha) {
            selectedTime = fecha.format("YYYY-MM-DD")
        } else {
            currentdate = new Date();
            selectedTime = currentdate.getFullYear() + "-" + (currentdate.getMonth() + 1) + "-" + currentdate.getDate();
        }
        /*if (selectedType === "") {
          setErrors({ type: "Elige un tipo de hora" });
          setLoading(false);
          return;
        }*/
        if (selectedProject === "") {
            setErrors({ project: "Elige un proyecto" });
            setLoading(false);
            return;
        }
        /*if (selectedDescription === "") {
          setErrors({ description: "Añade una descripción" });
          setLoading(false);
          return;
        }*/
        if (startTime.hours === 0 && startTime.minutes === 0) {
            let currentTime = new Date();
            startTime.hours = currentTime.getHours();
            startTime.minutes = currentTime.getMinutes();
        }

        if (totalTime.hours === 0 && totalTime.minutes === 0) {
            setErrors({ totalTime: "Elige un tiempo" });
            setLoading(false);
            return;
        }

        setErrors({});


        AsyncStorage.getItem("token").then((token) => {
            addTimesheet(
                token,
                selectedProject,
                selectedType,
                totalTime,
                selectedDescription,
                startTime,
                selectedTime,
                isReten,
            ).then((response) => {
                setLoading(false);
                if (response.error) {
                    alert(response.error.data.message);
                } else if (response.result.message == "ok") {
                    navigation.goBack();

                }
            });
        }
        );


    };

    const currentHour = () => {
        let currentdate = new Date();
        let hour = currentdate.getHours();
        let minutes = currentdate.getMinutes();
        setStartTime({ hours: hour, minutes: minutes });
    };


    const onDismiss = React.useCallback(() => {
        setVisibleStart(false);
    }, [setVisibleStart]);

    const onConfirm = React.useCallback(
        ({ hours, minutes }) => {
            console.log(selectedTimePicker);
            setStartTime({ hours, minutes });
            setVisibleStart(false);
        },
        [setVisibleStart]
    );

    const getProjecList = async () => {
        AsyncStorage.getItem("token").then((token) => {
            getProjectList(token).then((response) => {
                setProjectList(response.result.data);
            });
        });
    };

    const getRetenLists = async () => {
        const response = await getRetenList();
        setRetenList(response.result.data);
        console.log(response);
    };

    const onDismissTotal = React.useCallback(() => {
        setVisibleTotal(false);
    }, [setVisibleTotal]);

    const onConfirmTotal = React.useCallback(
        ({ hours, minutes }) => {
            console.log(selectedTimePicker);
            setTotalTime({ hours, minutes });
            setVisibleTotal(false);
        },
        [setVisibleTotal]
    );

    useEffect(() => {
        currentHour();
        getRetenLists();
        getProjecList();
    }, []);


    return (
        <>
            <View style={styles.form}>
                <ScrollView>
                    <Text style={styles.formTitle}>Añadir</Text>
                    <Text style={styles.subtitle}>
                        Rellena los campos para añadir un parte
                    </Text>
                    {fecha &&
                        <Text style={{
                            backgroundColor: 'white',
                            textAlign: 'center',
                            fontSize: 20,
                            paddingBottom: 10,
                            fontWeight: '600'
                        }}>Fecha: {fecha.format("DD-MM-YYYY")}</Text>
                    }


                    <View style={styles.inputGroup}>
                        <Text style={styles.text}>Tipo de hora:</Text>
                        <View style={styles.divider} />
                        <View style={{ flexGrow: 1 }}>
                            <SelectList
                                setSelected={(val) => setTypeSelected(val)}
                                data={taskTypes}
                                save="value"
                                label="Selecciona un tipo:"
                                placeholder="Selecciona un tipo"
                                searchPlaceholder="Buscar tipo de parte"
                                notFoundText="No se han encontrado resultados"
                                boxStyles={{ borderRadius: 3, borderWidth: 2 }}
                                dropdownStyles={{
                                    borderRadius: 3,
                                    borderWidth: 0.5,
                                    borderTopWidth: 0,
                                    marginTop: 0,
                                }}
                            />
                        </View>
                    </View>
                    {errors.type && <Text style={styles.error}>{errors.type}</Text>}

                    <Divider />


                    <View style={styles.inputGroup}>
                        <Text style={styles.text}>Proyecto:</Text>
                        <View style={styles.divider} />
                        <View style={{ flexGrow: 1 }}>
                            <SelectList
                                setSelected={(val) => setProjectSelected(val)}
                                data={projectList}
                                save="value"
                                label="Selecciona un proyecto:"
                                placeholder="Selecciona un proyecto"
                                searchPlaceholder="Buscar proyecto"
                                notFoundText="No se han encontrado resultados"
                                boxStyles={{ borderRadius: 3, borderWidth: 2 }}
                                dropdownStyles={{
                                    borderRadius: 3,
                                    borderWidth: 0.5,
                                    borderTopWidth: 0,
                                    marginTop: 0,
                                }}
                            />
                        </View>

                    </View>
                    {errors.project && (
                        <Text style={styles.error}>{errors.project}</Text>
                    )}
                    <Divider />

                    <View style={styles.inputGroup}>
                        <Text style={styles.text}>Descripcion:</Text>
                        <View style={styles.divider} />
                        {/*<TimePicker label="Hora de inicio"/>*/}
                        <TextInput
                            color="green"
                            variant="standard"
                            style={{ flexGrow: 1 }}
                            onChangeText={(text) => setDescription(text)}
                        />

                    </View>
                    {errors.description && (
                        <Text style={styles.error}>{errors.description}</Text>
                    )}
                    <Divider />

                    <View style={styles.inputGroup}>
                        <Text style={styles.text}>Hora de inicio:</Text>
                        <View style={styles.divider} />

                        <TouchableOpacity
                            style={{ flexGrow: 1 }}
                            onPress={() => setVisibleStart(true)}
                        >
                            <TextInput
                                color="green"
                                variant="standard"
                                style={{ textAlign: "center" }}
                                value={
                                    startTime.hours +
                                    (startTime.hours === 0 ? "0" : "") +
                                    ":" +
                                    startTime.minutes +
                                    (startTime.minutes === 0 ? "0" : "")
                                }
                            />
                        </TouchableOpacity>
                    </View>
                    {errors.startTime && (
                        <Text style={styles.error}>{errors.startTime}</Text>
                    )}
                    <Divider />
                    <View style={styles.inputGroup}>
                        <Text style={styles.text}>Horas trabajadas:</Text>
                        <View style={styles.divider} />
                        <TouchableOpacity
                            style={{ flexGrow: 1 }}
                            onPress={() => setVisibleTotal(true)}
                        >
                            <TextInput
                                color="green"
                                variant="standard"

                                style={{ flexGrow: 1 }}
                                value={
                                    totalTime.hours +
                                    (totalTime.hours === 0 ? "0" : "") +
                                    ":" +
                                    totalTime.minutes +
                                    (totalTime.minutes === 0 ? "0" : "")
                                }
                            />
                        </TouchableOpacity>
                        {/*<TimeField label="Hora de Inicio"*/}
                        {/*           value={startTime}*/}
                        {/*           style={{flexGrow: 1}}/>*/}
                        {/*<TimePicker label="Horas Trabajadas" style={{flexGrow: 1}} views={['hours', 'minutes']} value={totalTime} orientation={'portrait'} timezone={'system'} />*/}
                        {/*<MobileTimePicker label={"Horas Trabajadas"} style={{flexGrow: 1}}/>*/}
                        {/*<TimeInput label={"xsad"} />*/}
                        {/*<input type="time" id="appt" name="appt" required />*/}
                    </View>
                    {errors.totalTime && (
                        <Text style={styles.error}>{errors.totalTime}</Text>
                    )}
                    <Divider />
                    <View style={[styles.inputGroup, { display: altaReten ? 'flex' : 'none' }]}>
                        <Text style={styles.text}>Trabajo durante reten:</Text>
                        <Switch value={isReten} onValueChange={() => setReten(!isReten)} />
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 30 }}>
                        <Button title="Añadir Incidencia" style={[styles.buttons2, { textAlign: 'center' }]}
                            onPress={() => navigation.navigate("AddIncidenceToday")} />

                        <Button title="Añadir Ausencia" style={[styles.buttons2, { textAlign: 'center' }]}
                            onPress={() => navigation.navigate("AddLeave")} />
                    </View>

                    <TimePickerModal
                        visible={visibleStart}
                        onDismiss={onDismiss}
                        onConfirm={onConfirm}
                        hours={startTime.hours}
                        minutes={startTime.minutes}
                        label="Selecciona Hora"
                        cancelLabel="Cancelar"
                        confirmLabel="Confirmar"
                    />

                    <TimePickerModal
                        visible={visibleTotal}
                        onDismiss={onDismissTotal}
                        onConfirm={onConfirmTotal}
                        hours={totalTime.hours}
                        minutes={totalTime.minutes}
                        label="Horas Trabajadas"
                        cancelLabel="Cancelar"
                        confirmLabel="Confirmar"
                    />
                </ScrollView>
            </View>

            <View style={styles.footer}>
                <Button
                    style={styles.btn}
                    color="green"
                    title="CONFIRMAR"
                    titleStyle={styles.footerText}
                    onPress={() => {
                        handleAddTime();
                    }}
                    leading={(props) => (
                        <Icon
                            name="plus"
                            {...props}
                        />
                    )}
                    loading={loading}
                />
            </View>

        </>
    );
};

export default AddTimeScreen;
