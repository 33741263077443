import React from "react";
import LoginManagement from "../navigation/LoginManagement";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";

const Main = () => {
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <LoginManagement/>
            </LocalizationProvider>

        </>
    );
}


export default Main;