import React from "react";
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
    form: {
      flex: 1,
      backgroundColor: "#fff",
      padding: 30,
    },
    form2: {
      flex: 1,
      backgroundColor: "#fff",
      paddingBottom: 30,
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 5,
    },
    input: {
      width: "80%",
      padding: 15,
      marginBottom: 10,
      borderWidth: 1,
      borderColor: "#ccc",
      borderRadius: 3,
      flexGrow: 1,
    },
    btnContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "80%",
    },
    btn: {
      paddingVertical: 5,
    },
    btnText: {
      color: "darkblue",
      fontSize: 18,
      textAlign: "center",
    },
    formTitle: {
      fontWeight: "bold",
      fontSize: 40,
    },
    subtitle: {
      marginBottom: 20,
      fontSize: 18,
      color: "#ccc",
    },
    inputGroup: {
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      marginVertical: 5,
    },
    text: {
      flex: 1,
      flexGrow: 1,
    },
    divider: {
      width: 1,
      height: "100%",
      backgroundColor: "black",
      marginHorizontal: 10,
    },
    footer: {
      padding: 10,
      backgroundColor: "white",
    },
    footerText: {
      textAlign: "center",
      color: "white",
      fontSize: 18,
    },
    error: {
        color: "red",
        fontSize: 13,
        textAlign: "center",
        marginBottom: 6,
        },
    verTextData: {
        fontSize: 18,
        fontWeight: "bold",
    },
    buttons: {
        width: 125,
        fontSize: 0.75,
        margin: 5,
        backgroundColor: "green",
    },
    buttons2: {
      width: 125,
      fontSize: 0.75,
      margin: 5,
      backgroundColor: "grey",
  }
  });

    export default styles;