import ApiManager from "./ApiManager";

export const addTimesheet = async (token, project, taskType, hours, description, timeStart, currentDate, isReten) => {
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/addtimesheet',
            data: {
                "params": {token, project, taskType, hours, description, timeStart, currentDate, isReten}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}



export const updateTimesheet = async (isReten, token,itemId, project, taskType, hours, description, timeStart, currentDate) => {
    
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/updateTimesheet',
            data: {
                "params": {token,itemId, project, taskType, hours, description, timeStart, currentDate, isReten}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}


export const createTimesheetSheet = async (token, cobrarHoras,date) => {
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/createtimesheetsheet',
            data: {
                "params": {token,cobrarHoras,date}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const draftTimesheetSheet = async (token,date) => {
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/drattimesheetsheet',
            data: {
                "params": {token,date}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const checkReten = async (token,date) => {
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/checkreten',
            data: {
                "params": {token,date}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const altaRetenCall = async (token,date,projectId) => {
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/createreten',
            data: {
                "params": {token,date,projectId}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const deleteTimesheet = async (token,itemId) => {
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/deleteTimesheet',
            data: {
                "params": {token,itemId}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}
