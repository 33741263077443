import ApiManager from "./ApiManager";

export const createIncidence= async (token, incidence_type, date_start, date_end, duration, description) => {
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/createhrincidence',
            data: {
                "params": {token, incidence_type, date_start, date_end, duration, description}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const createTodayIncidence= async (token, incidence_type, quantity, amount, description, project) => {
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/createhrincidencetoday',
            data: {
                "params": {token, incidence_type, quantity, amount, description, project}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}


export const getIncidenceType = async (token) => {
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/hrincidencetype',
            data: {
                "params": {token}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}


export const getHrIncidence = async (token, date) => {
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/hrincidence',
            data: {
                "params": {token, date}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}
