import React, {useEffect, useState} from 'react';
import { View, Text } from 'react-native';
import EditScreen from './EditScreen';
import ViewScreen from './ViewScreen';
import { SelectList } from "react-native-dropdown-select-list";
import useTaskTypes from "../../../hooks/useTaskType";
import { getProjectList } from '../../../api/projectApi';
const ViewEditScreen = ({ route, navigation }) => {
  const { item } = route.params;
  const [editable, setEditable] = React.useState(false);
  const [projectList, setProjectList] = useState([]);
  const { taskTypes } = useTaskTypes();


  const getProjecLists = async () => {
    const response = await getProjectList();
    setProjectList(response.result.data);
  };


  useEffect(() => {
    if (item.state != "done" & item.state != "confirm") {
      getProjecLists().then(() => {
        setEditable(true);
      });
    }
    else {
      setEditable(false);
    }
    console.log(item);
  }, []);

  

  return (
    <View>
      {editable ? (
        <EditScreen navigation={navigation} item={item} projectList={projectList} taskTypes={taskTypes} />
      ) : (
        <ViewScreen item={item} />
      )}
    </View>
  );
};

export default ViewEditScreen;
