import React, { useEffect, useState } from "react";
import { View } from "react-native";
import Webcam from "react-webcam";
import AsyncStorage from "@react-native-async-storage/async-storage";


const TakePhoto = ({ navigation }) => {
    const webcamRef = React.useRef(null);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const capture = async () => {
        const imageSrc = webcamRef.current.getScreenshot();
        return imageSrc;
    }
    const save = () => {
        capture().then((image) => {
            saveData(image);
        }
        )
    }

    const saveData = async (image) => {
        console.log(image);
        try {
            await AsyncStorage.setItem('image', image);
            navigation.goBack();
        } catch (e) {
            console.log(e);
        }
    }

    function getWindowSize() {
        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
    }

    useEffect(() => {
        const handleResize = () => {
            const { width, height } = getWindowSize();
            setWindowSize({ width, height });
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }
    );

    return (
        <>
            <Webcam
                audio={false}
                videoConstraints={{
                    width: windowSize.height * 0.7,
                    height: windowSize.width,
                    facingMode: "environment"
                }}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
            />
            <button onClick={save} style={{ width: 80, height: 80, borderRadius: 100, justifyContent: 'center', alignSelf: 'center', backgroundColor: 'lightgray', marginTop: 5 }}></button>
        </>
    );
}

export default TakePhoto;