import React, {useEffect, useState}  from "react";
import { getTaskType } from "../api/projectApi";


const useTaskTypes = () => {

    const [taskTypes, setTaskTypes] = useState(null); // eslint-disable-line
  
    const fetchTaskTypes = async () => {
        const response = await getTaskType();
        setTaskTypes(response.result.data);
    };
  
    useEffect(() => {
        fetchTaskTypes()
    }, [])
  
  
    return {taskTypes : taskTypes };
  
  }

    export default useTaskTypes;