import React from "react";
import {createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import AddTimeScreen from "../screens/TimeScreens/AddTimeScreen";
import HomeStackScreen from "./HomeStackScreen";
import OthersStackScreen from "./OthersStackScreen";
import { Entypo } from '@expo/vector-icons'; 
import HistoryScreen from "../screens/HistoryScreen/HistoryScreen";
import LeaveStackScreen from "./LeaveStackScreen";
const Tab = createBottomTabNavigator();

function MyTabs( ) {



    return (
      <Tab.Navigator
        initialRouteName={"Home"}
        screenOptions={{
          tabBarActiveTintColor: "green",
        }}
      >
        <Tab.Screen
         name="Home" component={HomeStackScreen}
         options={{
            tabBarLabel: "Inicio",
            tabBarIcon: ({ color, size }) => (
              <MaterialCommunityIcons name="home" color={color} size={size} />
            ),
            headerShown: false,
         }}
         />
        <Tab.Screen
         name="Ausencias" component={LeaveStackScreen}
          options={{
            tabBarLabel: "Ausencias",
            tabBarIcon: ({ color, size }) => (
              <MaterialCommunityIcons name="medical-bag" color={color} size={size} />
            ),
            headerShown: false,
          }}
         />
         <Tab.Screen
         name="Otros" component={OthersStackScreen}
          options={{
            tabBarLabel: "Otros",
            tabBarIcon: ({ color, size }) => (
              <Entypo name="dots-three-horizontal" size={size} color={color} />
            ),
            headerShown: false,
          }}
         />
      </Tab.Navigator>
    );
  }

export default MyTabs;