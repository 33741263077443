import ApiManager from "./ApiManager";

export const checkApi = async (data) => {
    try {
        const response = await ApiManager.post('/check', {
            method: 'POST',
            data: []
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}
