import React from "react";
import { View, Text } from "react-native";
import styles from "../AddTimeScreenStyles";
import { Badge } from "react-native-paper";

const ViewScreen = ({ item }) => {
  return (
    <View style={styles.form}>
      <View style={styles.inputGroup}>
        <Text style={ [styles.formTitle, {flexGrow: 1}]}>Ver parte:</Text>
        <Badge size={30} style={{ backgroundColor: item.state == "confirm" ? "skyblue" : item.state == "done" ? "green" : "" }}>
          {item.state == "confirm" ? "En espera" : item.state == "done" ? "Validado" : ""}
        </Badge>
      </View>
      <Text style={styles.subtitle}>{item.date}</Text>
      <View>
        <View style={styles.inputGroup}>
          <Text style={styles.text}>Proyecto: </Text>
          <Text style={styles.verTextData}>{item.project_id.name}</Text>
        </View>
        <View style={styles.inputGroup}>
          <Text style={styles.text}>Inicio: </Text>
          <Text style={styles.verTextData}>{item.time_start}</Text>
        </View>
        <View style={styles.inputGroup}>
          <Text style={styles.text}>Fin:</Text>
          <Text style={styles.verTextData}>{item.time_stop}</Text>
        </View>
        <View style={styles.inputGroup}>
          <Text style={styles.text}>Total:</Text>
          <Text style={styles.verTextData}> {item.unit_amount}</Text>
        </View>
        <View style={styles.inputGroup}>
          <Text style={styles.text}>Descripción:</Text>
          <Text style={styles.verTextData}>{item.name}</Text>
        </View>
      </View>
    </View>
  );
};

export default ViewScreen;
