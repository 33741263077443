import React from "react";
import { View } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import ResultDay from "../../screens/OtherDayScreen/ResultDay";
import AddTimeScreen from "../../screens/TimeScreens/AddTimeScreen";
import ViewEditScreen from "../../screens/TimeScreens/ViewEditScreens/ViewEditScreen";
import { mdiReceiptTextSend, mdiReceiptTextArrowLeftOutline, mdiFlagPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { Stack, IconButton } from "@react-native-material/core";

const ResultStack = createStackNavigator();

function ResultDayStack({ route, navigation }) {
    const { date } = route.params;
    
    const handlePress = () => {
        navigation.navigate("SendSheetStack", { date: date });
    }

    return (
        <ResultStack.Navigator initialRouteName="ResultDay">
            <ResultStack.Screen options={{
                title: "Resultado", headerRight: () => (
                    <View style={{ flexDirection: "row" }}>
                        <IconButton
                            onPress={handlePress}
                            icon={(props) => <Icon path={mdiReceiptTextSend} size={1} />}
                        />
                    </View>
                ),
            }} name="ResultDay" component={ResultDay} initialParams={{ date: date }} />
            <ResultStack.Screen
                options={{ title: "Añadir Parte" }}
                name="AddTime"
                component={AddTimeScreen}
            />
            <ResultStack.Screen
                options={{ title: "Ver Parte" }}
                name="ViewEdit"
                component={ViewEditScreen}
            />
        </ResultStack.Navigator>
    );
}

export default ResultDayStack;