import ApiManager from "./ApiManager";

export const getTaskType = async () => {
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/projecttimetype',
            data: {
                "params": {}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}


export const getProjectList = async (token) => {
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/employeeprojectlist',
            data: {
                "params": {token}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const getRetenList = async () => {
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/getretenlist',
            data: {
                "params": {}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}