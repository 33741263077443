import React, { useEffect, useState } from "react";
import {
    View,
    Text,
    StyleSheet,
    Touchable,
    Image,
    TouchableOpacity,
} from "react-native";
import {
    Stack,
    TextInput,
    IconButton,
    Divider,
    Button,
    Switch,
} from "@react-native-material/core";
import { SelectList } from "react-native-dropdown-select-list";
import { TimePickerModal } from "react-native-paper-dates";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { getProjectList, getRetenList } from "../../api/projectApi";
import styles from "../TimeScreens/AddTimeScreenStyles";
import { createLeaveDay, getLeaveType } from "../../api/leaveApi";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CalendarPicker from "react-native-calendar-picker";
import { createLeave } from "../../api/leaveApi";
import { ScrollView } from "react-native-gesture-handler";
import { useFocusEffect } from "@react-navigation/native";

const AddLeaveScreen = ({ navigation }) => {
    const [porDias, setPorDias] = useState(false);
    const [selectedType, setTypeSelected] = useState("");
    const [selectedProject, setProjectSelected] = useState("");
    const [selectedTask, setTaskSelected] = useState("");
    const [startTime, setStartTime] = useState({ hours: 0, minutes: 0 });
    const [totalTime, setTotalTime] = useState({ hours: 0, minutes: 0 });
    const [selectedDescription, setDescription] = useState("");
    const [errors, setErrors] = useState({});
    const [leaveTypes, setleaveTypes] = useState([]);
    const [projectList, setProjectList] = useState([]);

    const [selectedTimePicker, setSelectedTimePicker] = useState("");

    const [visibleTotal, setVisibleTotal] = useState(false);
    const [visibleStart, setVisibleStart] = useState(false);

    const [loading, setLoading] = useState(false);

    const [imagen, setImagen] = useState('');
    const [showImage, setShowImage] = useState(false);

    const handleAddTime = () => {
        setLoading(true);
        AsyncStorage.getItem("token").then((value) => {
            if (value !== null) {
                let startDate = new Date()
                let endDate = startDate

                startDate.setHours(totalTime.hours)
                startDate.setMinutes(totalTime.minutes)

                AsyncStorage.setItem('image', null)

                if (porDias) {
                    createLeave(
                        value,
                        selectedType,
                        startDate,
                        endDate,
                        selectedDescription,
                        selectedProject
                    ).then((response) => {
                        if (response.error.code == 200) {
                            setErrors(response.result.data);
                            navigation.goBack()
                        } else if (response.result.message === "ok") {
                            navigation.goBack()
                        }
                    });
                } else {
                    console.log("por dia hora");
                    createLeaveDay(
                        value,
                        selectedType,
                        startDate,
                        startTime,
                        totalTime,
                        selectedDescription,
                        imagen,
                        selectedProject
                    ).then((response) => {
                        console.log(response);
                        if (response.error && response.error.code === 200) {
                            setErrors(response.error.message);
                            navigation.goBack()
                        } else if (response.result.message === "ok") {
                            navigation.goBack()
                        }
                    });
                }
            }
        }
        );
    };

    const getImagen = async () => {
        try {
            AsyncStorage.getItem('image').then((value) => {
                if (value.length > 6) {
                    setImagen(value)
                }
            }
            )
        } catch (e) {
            console.log(e)
        }
    }

    const getProjecList = async () => {
        AsyncStorage.getItem("token").then((token) => {
            getProjectList(token).then((response) => {
                setProjectList(response.result.data);
            });
        });
    };

    const onDismiss = React.useCallback(() => {
        setVisibleStart(false);
    }, [setVisibleStart]);

    const onConfirm = React.useCallback(
        ({ hours, minutes }) => {
            console.log(selectedTimePicker);
            setStartTime({ hours, minutes });
            setVisibleStart(false);
        },
        [setVisibleStart]
    );

    const getTypeLeave = async () => {
        AsyncStorage.getItem("token").then((value) => {
            if (value !== null) {
                getLeaveType(value, 'horas').then((response) => {
                    setleaveTypes(response.result.data);
                });
            }
        });

    };

    const onDismissTotal = React.useCallback(() => {
        setVisibleTotal(false);
    }, [setVisibleTotal]);

    const onConfirmTotal = React.useCallback(
        ({ hours, minutes }) => {
            console.log(selectedTimePicker);
            setTotalTime({ hours, minutes });
            setVisibleTotal(false);
        },
        [setVisibleTotal]
    );

    useEffect(() => {
        getTypeLeave();
        getProjecList();
        getImagen();
    }, []);

    useFocusEffect(
        React.useCallback(() => {
            getImagen();
        }, [])
    );

    return (
        <>
            <ScrollView>
                <View style={styles.form}>
                    <Text style={styles.formTitle}>Añadir</Text>
                    <Text style={styles.subtitle}>
                        Rellena los campos para añadir una ausencia
                    </Text>

                    <View style={styles.inputGroup}>
                        <Text style={styles.text}>Tipo de ausencia:</Text>
                        <View style={styles.divider} />
                        <View style={{ flexGrow: 1 }}>
                            <SelectList
                                setSelected={(val) => setTypeSelected(val)}
                                data={leaveTypes}
                                save="value"
                                label="Selecciona un tipo:"
                                placeholder="Selecciona un tipo"
                                searchPlaceholder="Buscar Ausencia"
                                notFoundText="No se han encontrado resultados"
                                boxStyles={{ borderRadius: 3, borderWidth: 2 }}
                                dropdownStyles={{
                                    borderRadius: 3,
                                    borderWidth: 0.5,
                                    borderTopWidth: 0,
                                    marginTop: 0,
                                }}
                            />
                        </View>
                    </View>
                    <View style={styles.inputGroup}>
                        <Text style={styles.text}>Proyecto:</Text>
                        <View style={styles.divider} />
                        <View style={{ flexGrow: 1 }}>
                            <SelectList
                                setSelected={(val) => setProjectSelected(val)}
                                data={projectList}
                                save="value"
                                label="Selecciona un proyecto:"
                                placeholder="Selecciona un proyecto"
                                searchPlaceholder="Buscar proyecto"
                                notFoundText="No se han encontrado resultados"
                                boxStyles={{ borderRadius: 3, borderWidth: 2 }}
                                dropdownStyles={{
                                    borderRadius: 3,
                                    borderWidth: 0.5,
                                    borderTopWidth: 0,
                                    marginTop: 0,
                                }}
                            />
                        </View>

                    </View>
                    {errors.project && <Text style={styles.error}>{errors.project}</Text>}
                    <Divider />
                    <View style={styles.inputGroup}>
                        <Text style={styles.text}>Descripcion:</Text>
                        <View style={styles.divider} />
                        <TextInput
                            color="green"
                            variant="standard"
                            style={{ flexGrow: 1 }}
                            onChangeText={(text) => setDescription(text)}
                        />
                    </View>
                    {errors.description && (
                        <Text style={styles.error}>{errors.description}</Text>
                    )}
                    <Divider />
                    {!porDias ? (
                        <>
                            <View style={styles.inputGroup}>
                                <Text style={styles.text}>Hora de inicio:</Text>
                                <View style={styles.divider} />
                                <TouchableOpacity
                                    style={{ flexGrow: 1 }}
                                    onPress={() => setVisibleStart(true)}
                                >
                                    <TextInput
                                        color="green"
                                        variant="standard"
                                        style={{ textAlign: "center" }}
                                        value={
                                            startTime.hours +
                                            (startTime.hours === 0 ? "0" : "") +
                                            ":" +
                                            startTime.minutes +
                                            (startTime.minutes === 0 ? "0" : "")
                                        }
                                    />
                                </TouchableOpacity>
                            </View>
                            {errors.startTime && (
                                <Text style={styles.error}>{errors.startTime}</Text>
                            )}
                            <Divider />
                            <View style={styles.inputGroup}>
                                <Text style={styles.text}>Horas:</Text>
                                <View style={styles.divider} />
                                <TouchableOpacity
                                    style={{ flexGrow: 1 }}
                                    onPress={() => setVisibleTotal(true)}
                                >
                                    <TextInput
                                        color="green"
                                        variant="standard"
                                        style={{ flexGrow: 1 }}
                                        value={
                                            totalTime.hours +
                                            (totalTime.hours === 0 ? "0" : "") +
                                            ":" +
                                            totalTime.minutes +
                                            (totalTime.minutes === 0 ? "0" : "")
                                        }
                                    />
                                </TouchableOpacity>
                            </View>
                            {errors.totalTime && (
                                <Text style={styles.error}>{errors.totalTime}</Text>
                            )}
                            <Divider />
                        </>
                    ) : null}
                    {/*<View style={styles.inputGroup}>
            <Text style={styles.text}>Dia entero:</Text>
            <Switch
              style={{ alignSelf: "center" }}
              value={porDias}
              onValueChange={() => setPorDias(!porDias)}
            />
                            </View> */}

                    <TimePickerModal
                        visible={visibleStart}
                        onDismiss={onDismiss}
                        onConfirm={onConfirm}
                        hours={0}
                        minutes={0}
                        label="Selecciona Hora"
                        cancelLabel="Cancelar"
                        confirmLabel="Confirmar"
                    />

                    <TimePickerModal
                        visible={visibleTotal}
                        onDismiss={onDismissTotal}
                        onConfirm={onConfirmTotal}
                        hours={0}
                        minutes={0}
                        label="Selecciona Hora"
                        cancelLabel="Cancelar"
                        confirmLabel="Confirmar"
                    />
                    {errors.description && (
                        <Text style={styles.error}>{errors.description}</Text>
                    )}
                    <Divider />
                    {imagen.length > 6 && (
                        <Image
                            style={{ width: 200, height: 200, alignSelf: 'center' }}
                            source={{
                                uri: imagen
                            }}
                        />
                    )}
                    {/*
                    <Button
                        style={[styles.btnText]}
                        color="grey"
                        title="Añadir justificante"
                        titleStyle={styles.footerText}
                        onPress={() => {
                            navigation.navigate("TakePhoto")
                        }}
                    />*/}
                    <View style={styles.footer}>
                        <Button
                            style={styles.btn}
                            color="green"
                            title="CONFIRMAR"
                            titleStyle={styles.footerText}
                            onPress={() => {
                                handleAddTime();
                            }}
                            leading={(props) => <Icon name="plus" {...props} />}
                            loading={loading}
                        />
                    </View>
                </View>
            </ScrollView>
        </>
    );
};

export default AddLeaveScreen;
