import React from "react";
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    TouchableHighlight,
} from "react-native";
import {Divider} from "@react-native-material/core";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import {Badge} from "react-native-paper";

const style = StyleSheet.create({
    itemlist: {
        flex: 1,
        backgroundColor: "#fff",
        justifyContent: "space-around",
        marginVertical: 0,
        padding: 10,
    },
    itemlistHeader: {
        flex: 1,
        backgroundColor: "#fff",
        justifyContent: "space-around",
        flexDirection: "row",
        marginTop: 2,
        marginBottom: 10,
    },
    itemlistBody: {
        flex: 1,
        backgroundColor: "#fff",
        justifyContent: "space-between",
        flexDirection: "row",
        marginTop: 0,
    },
    itemText: {
        fontSize: 20,
    },
    itemText2: {
        fontSize: 13,
        marginHorizontal: 10,
    },
});

const IncidencelistItem = ({navigation, props}) => {
    return (
        <>
            <TouchableOpacity
                style={style.itemlist}
                onPress={() => console.log("click")}
            >
                <View style={style.itemlistHeader}>
                    {/*<Badge
            size={30}
            style={{
              backgroundColor:
                props.state == "confirm"
                  ? "skyblue"
                  : props.state == "draft"
                    ? "grey"
                    : !props.state
                      ? "grey"
                      : "",
            }}
          >
            {props.state == "confirm"
              ? "Confirmado"
              : props.state == "draft"
                ? "Sin Aprobar"
                : !props.state
                  ? "Sin enviar"
                  : ""}
          </Badge>*/}
                    <Badge
                        size={30}
                        style={{
                            backgroundColor: props.is_leave ? 'orange' : 'red'
                        }}
                    >
                        {props.is_leave ? 'Ausencia' : 'Incidencia'}
                        {props.is_leave}
                    </Badge>
                    <Text style={style.itemText}>{props.tipo_incidencia[1]}</Text>
                    {/*<Text style={style.itemText}>*/}
                    {/*    {props.duration}*/}
                    {/*    <Text style={{fontSize: 17}}> Hora/s</Text>*/}
                    {/*</Text>*/}
                    {!props.no_contar_horas && (
                        <Text style={style.itemText}>
                        </Text>
                    )
                    }
                </View>

                <View style={style.itemlistBody}>
                    {props.project_id && (
                        <TouchableHighlight>
                            <Text style={[style.itemText2]}>
                                Proyecto:{" "}
                                <Text style={{color: "skyblue", fontWeight: "700"}}>
                                    {props.project_id.name}
                                </Text>
                            </Text>
                        </TouchableHighlight>
                    )}
                    <Text style={style.itemText2}>Fecha
                        inicio:{props.date_start}</Text>
                    <Text style={style.itemText2}>Fecha
                        fin:{props.date_end}</Text>
                </View>
            </TouchableOpacity>
            <Divider/>
        </>
    );
};

export default IncidencelistItem;
