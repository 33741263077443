import ApiManager from "./ApiManager";

export const getLeaves = async (token) => {
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/ausencias',
            data: {
                "params": {token}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const getTodayLeaves = async (token) => {
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/ausenciasToday',
            data: {
                "params": {token}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}


export const getLeaveType = async (token, type) => {
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/leavetype',
            data: {
                "params": {token, type}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}


export const createLeave = async (token, leave_type, date_from, date_to, name, project) => {
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/createleave',
            data: {
                "params": {token, leave_type, date_from, date_to, name, project}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}


export const createLeaveDay = async (token, leave_type, date_from, startTime, totalTime, name, image, project) => {
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/createleave',
            data: {
                "params": {token, leave_type, date_from, startTime, totalTime, name, image, project}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}