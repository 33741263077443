import React, { useEffect, useState } from "react";
import {
    View,
    Text,
} from "react-native";
import {

    Button,
} from "@react-native-material/core";
import { SelectList } from "react-native-dropdown-select-list";
import { altaRetenCall } from "../../api/timesheetApi";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { getProjectList } from "../../api/projectApi";
import styles from "../TimeScreens/AddTimeScreenStyles";
import AsyncStorage from "@react-native-async-storage/async-storage";

const AltaRetenScreen = ({ navigation }) => {
    const [selectedProject, setProjectSelected] = useState("");
    const [projectList, setProjectList] = useState([]);
    const [errors, setErrors] = useState({});

    const handleAddTime = () => {

        if (selectedProject === "") {
            setErrors({ project: "Elige un proyecto" });
            setLoading(false);
            return;
        }



        altaRetenApi();


    };

    const altaRetenApi = async (token) => {
        if (token === undefined || !token) {
            AsyncStorage.getItem("token").then((value) => {
                if (value !== null) {
                    console.log(value);
                    altaRetenApi(value);
                }
            });
        } else {
            const response = await altaRetenCall(token, false, selectedProject);
            console.log(response);
            navigation.goBack();
        }
    }

    const getProjecList = async () => {
        AsyncStorage.getItem("token").then((token) => {
            getProjectList(token).then((response) => {
                setProjectList(response.result.data);
            });
        });
    };

    useEffect(() => {
        getProjecList();
    }, []);

    return (
        <>
            <View style={[styles.form] }>
                <View style={[styles.inputGroup, {marginTop: 200}]}>
                    <Text style={styles.text}>Proyecto:</Text>
                    <View style={styles.divider} />
                    <View style={{ flexGrow: 1 }}>
                        <SelectList
                            setSelected={(val) => setProjectSelected(val)}
                            data={projectList}
                            save="value"
                            label="Selecciona un proyecto:"
                            placeholder="Selecciona un proyecto"
                            searchPlaceholder="Buscar proyecto"
                            notFoundText="No se han encontrado resultados"
                            boxStyles={{ borderRadius: 3, borderWidth: 2 }}
                            dropdownStyles={{
                                borderRadius: 3,
                                borderWidth: 0.5,
                                borderTopWidth: 0,
                                marginTop: 0,
                            }}
                        />
                    </View>

                </View>


                <View style={[styles.footer ]}>
                    <Button
                        style={styles.btn}
                        color="green"
                        title="CONFIRMAR"
                        titleStyle={styles.footerText}
                        onPress={() => {
                            handleAddTime();
                        }}
                        leading={(props) => (
                            <Icon
                                name="plus"
                                {...props}
                            />
                        )}
                    />
                </View>
            </View>
        </>
    );
};

export default AltaRetenScreen;
