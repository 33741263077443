import React from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  TouchableHighlight,
} from "react-native";
import { Divider } from "@react-native-material/core";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { Badge } from "react-native-paper";

const style = StyleSheet.create({
  itemlist: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "space-around",
    marginVertical: 0,
    padding: 15,
  },
  itemlistHeader: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "space-around",
    flexDirection: "row",
    marginTop: 2,
  },
  itemlistBody: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 20,
  },
  itemText: {
    fontSize: 20,
  },
  itemText2: {
    fontSize: 13,
    marginHorizontal: 10,
  },
});

const LeavelistItem = ({ navigation, props }) => {

  return (
    <>
      <TouchableOpacity
        style={style.itemlist}
        onPress={() => console.log("pressed", props)}
      >
        <View style={style.itemlistHeader}>
        {props.holiday_status_id[1] && (
            <Text style={style.itemText}>{props.holiday_status_id[1]}</Text>
          )}
          <Badge
            size={30}
            style={{
              backgroundColor:
                props.state == "confirm"
                  ? "skyblue"
                  : props.state == "validate"
                  ? "green"
                  : props.state == "refuse"
                  ? "red"
                  : !props.state
                  ? "grey"
                  : "",
            }}
          >
            {props.state == "confirm"
              ? "Por aprobar"
              : props.state == "validate"
              ? "Aprobado"
              : props.state == "refuse"
              ? "Rechazado"
              : !props.state
              ? "Sin enviar"
              : ""}
          </Badge>
          
        </View>
      
        <View style={style.itemlistBody}>
          <Text style={style.itemText2}>{props.name}</Text>
          <Text style={style.itemText2}>
            <Text style={{ fontSize: 13 }}> Desde: </Text>
            {props.date_from}
          </Text>
          <Text style={style.itemText2}>
            <Text style={{ fontSize: 13 }}> Hasta: </Text>
            {props.date_to}
          </Text>
        </View>
      </TouchableOpacity>
      <Divider />
    </>
  );
};

export default LeavelistItem;
