import { useEffect, useState } from "react";
import React from "react";
import { View, Text } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { getAnyDayTimesheets } from "../../api/getTimesheets";
import { Stack, ActivityIndicator } from "@react-native-material/core";
import AsyncStorage from "@react-native-async-storage/async-storage";
import BasiclistItem from "../../components/HomeComponents/BasicListItem";
import { FlatList, ScrollView } from "react-native-gesture-handler";
import { FAB } from "@rneui/themed";
import IncidencelistItem from "../../components/HomeComponents/IncidenceItem";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { Divider } from "react-native-paper";
import { getHrIncidence } from "../../api/IncidenceApi";

const ResultDay = ({ route, navigation }) => {
    const { date } = route.params;
    const [token, setToken] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [closedDay, setClosedDay] = useState(false);
    const [incidencias, setIncidencias] = useState([]); //array de incidencias [{}

    useFocusEffect(
        React.useCallback(() => {
            getAnyDayTimesheetsCall();
        }, [])
    );

    const getAnyDayTimesheetsCall = async () => {
        const token = await AsyncStorage.getItem("token");
        setToken(token);
        const response = await getAnyDayTimesheets(token, date);
        if (response.result.message == "ok") {
            setData(response.result.partes);
            setError("");
            AsyncStorage.setItem(
                "horas_calendario",
                JSON.stringify(response.result.horas_calendario)
            );
            AsyncStorage.setItem(
                "horas_trabajadas",
                JSON.stringify(response.result.total_time)
            );
        } else {
            setError(response.result.message);
        }
        setClosedDay(response.result.dia_cerrado);
        setLoading(false);

        getHrIncidence(token, date)
        .then((response) => {
          console.log(response);
          if (response.result.message == "ok") {
            setIncidencias(response.result.data);
          }
        }
        )

    }

    return (
        <>
            <Text style={{ backgroundColor: 'white', textAlign: 'center', fontSize: 20, paddingVertical: 10, fontWeight: '600' }}>Fecha: {date.format("DD-MM-YYYY")}</Text>

            <Divider />
            <ScrollView>

                {loading &&
                    <ActivityIndicator size="large" color="error" />
                }
                {error !== "" &&
                    <Text>{error}</Text>
                }
                <FlatList
                    data={data}
                    renderItem={({ item }) => (
                        <BasiclistItem navigation={navigation} props={item} />
                    )}
                    keyExtractor={(item) => item.id.toString()}
                />
                <FlatList
                    data={incidencias}
                    renderItem={({ item }) => (
                        <IncidencelistItem navigation={navigation} props={item} />
                    )}
                    keyExtractor={(item) => item.id.toString()}
                />


            </ScrollView>
            <FAB
                icon={(props) => (
                    <Icon name="plus" color="white" size={22} {...props} />
                )}
                color="green"
                placement="right"
                onPress={() => navigation.navigate("AddTime", { fecha: date })}
            />
        </>
    );
}

export default ResultDay;