import React from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  TouchableHighlight,
} from "react-native";
import { Divider } from "@react-native-material/core";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { Badge } from "react-native-paper";

const style = StyleSheet.create({
  itemlist: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "space-around",
    marginVertical: 0,
    padding: 10,
  },
  itemlistHeader: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "space-around",
    flexDirection: "row",
    marginTop: 2,
  },
  itemlistBody: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 0,
  },
  itemText: {
    fontSize: 20,
  },
  itemText2: {
    fontSize: 13,
    marginHorizontal: 10,
  },
});

const BasiclistItem = ({ navigation, props }) => {
  return (
    <>
      <TouchableOpacity
        style={style.itemlist}
        onPress={() => navigation.navigate("ViewEdit", { item: props })}
      >
        <View style={style.itemlistHeader}>
          <Badge
            size={30}
            style={{
              backgroundColor:
                props.state == "confirm"
                  ? "skyblue"
                  : props.state == "done"
                  ? "green"
                  : props.state == "draft"
                  ? "grey"
                  : !props.state
                  ? "grey"
                  : "",
            }}
          >
            {props.state == "confirm"
              ? "En espera"
              : props.state == "done"
              ? "Validado"
              : props.state == "draft"
              ? "Borrador"
              : !props.state
              ? "Sin enviar"
              : ""}
          </Badge>
          {props.is_reten && (
            <Badge
              size={25}
              style={{
                backgroundColor: 'black',
                color: 'white',
                
              }}
            >
              <Text style={{color: 'white', padding: 5}}>Reten</Text>
            </Badge>
          )}
          {props.task_id.name && (
            <Text style={style.itemText}>{props.task_id.name}</Text>
          )}
          <Text style={style.itemText}>{props.name}</Text>
          <Text style={style.itemText}>
            {props.unit_amount}
            <Text style={{ fontSize: 17 }}> Hora/s</Text>
          </Text>
        </View>
        <Icon
          name="chevron-right"
          style={{ alignSelf: "flex-end" }}
          size={25}
          color={"green"}
        />

        <View style={style.itemlistBody}>
          {props.project_id.name && (
            <TouchableHighlight>
              <Text style={[style.itemText2]}>
                Proyecto:{" "}
                <Text style={{ color: "skyblue", fontWeight: "700" }}>
                  {props.project_id.name}
                </Text>
              </Text>
            </TouchableHighlight>
          )}
          {(props.time_start.split(":")[0] > 0) |
            props.time_start.split(":")[1] && (
            <Text style={style.itemText2}>Hora Inicio:{props.time_start}</Text>
          )}
          {(props.time_stop.split(":")[0] > 0) |
            props.time_stop.split(":")[1] && (
            <Text style={style.itemText2}>Hora Fin:{props.time_stop}</Text>
          )}
        </View>
      </TouchableOpacity>
      <Divider />
    </>
  );
};

export default BasiclistItem;
