import { useFocusEffect } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { FlatList, ScrollView } from "react-native-gesture-handler";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
// import {FAB, ListItem} from "@rneui/themed";
// import Fab from "./Fab/Fab";
import { FAB } from 'react-native-paper';

import BasiclistItem from "../components/HomeComponents/BasicListItem";
import IncidencelistItem from "../components/HomeComponents/IncidenceItem";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getTodayTimesheets } from "../api/getTimesheets";
import { Snackbar } from "@react-native-material/core";
import { checkReten } from "../api/timesheetApi";
import { getHrIncidence } from "../api/IncidenceApi";
import { getTodayLeaves } from "../api/leaveApi";

const HomeScreen = ({ navigation }) => {
    const [partes, setPartes] = useState([]); //array de partes de trabajo [{}
    const [incidencias, setIncidencias] = useState([]); //array de incidencias [{}
    const [ausencias, setAusencias] = useState([]);
    const [noPartes, setNoPartes] = useState(false); //no hay partes de trabajo [true, false
    const [alertSnackVisible, setAlertSnackVisible] = useState("none"); //alerta de error [true, false
    const [expanded, setExpanded] = useState();

    const [altaReten, setAltaReten] = useState(false);

    useFocusEffect(React.useCallback(() => {
        handleApiTimesheets();
        checkRetenCall();
    }, []));

    const checkRetenCall = async (token) => {
        if (token === undefined) {
            AsyncStorage.getItem("token").then((value) => {
                if (value !== null) {
                    checkRetenCall(value);
                }
            });
        } else {
            const response = await checkReten(token);
            setAltaReten(response.result.message == "false" ? false : true);
            AsyncStorage.setItem("altaReten", response.result.message);
        }
    };

    const handleApiTimesheets = () => {
        AsyncStorage.getItem("token").then((token) => {
            getTodayTimesheets(token)
                .then((response) => {
                    console.log(response);
                    if (response.result.message == "notimesheet") {
                        setNoPartes(true);
                        AsyncStorage.setItem("horas_calendario", JSON.stringify(response.result.horas_calendario));
                        AsyncStorage.setItem("horas_trabajadas", JSON.stringify(response.result.total_time));
                    } else if (response.result.message == "erremployee") {
                        AsyncStorage.removeItem("token");
                        navigation.replace("Login");
                    } else if (response.result.message == "ok") {
                        setNoPartes(false);
                        setPartes(response.result.partes);

                        AsyncStorage.setItem("horas_calendario", JSON.stringify(response.result.horas_calendario));
                        AsyncStorage.setItem("horas_trabajadas", JSON.stringify(response.result.total_time));
                    }
                })
                .catch((error) => {
                    navigation.replace("Login");
                });
            getHrIncidence(token)
                .then((response) => {
                    if (response.result.message == "ok") {
                        setNoPartes(false);
                        setIncidencias(response.result.data);
                    }
                })
            getTodayLeaves(token)
                .then((response) => {
                    console.log(response)
                    if (response.result.message == "ok") {
                        setAusencias(response.result.data)
                    }
                })

        });
    };

    return (<>
        <ScrollView>
            {noPartes && (<View style={{ margin: 20 }}>
                <Text
                    style={{
                        color: "red", textAlign: "center", fontWeight: "500", fontSize: 20,
                    }}
                >
                    No hay partes de trabajo
                </Text>
            </View>)}
            <FlatList
                data={partes}
                renderItem={({ item }) => (<BasiclistItem navigation={navigation} props={item} />)}
                keyExtractor={(item) => item.id.toString()}
            />
            <FlatList
                data={incidencias}
                renderItem={({ item }) => (<IncidencelistItem navigation={navigation} props={item} />)}
                keyExtractor={(item) => item.id.toString()}
            />
            <FlatList
                data={ausencias}
                renderItem={({ item }) => (<IncidencelistItem navigation={navigation} props={item} />)}
                keyExtractor={(item) => item.id.toString()}
            />

        </ScrollView>
        {
            expanded ? (
                <div
                    style={
                        !altaReten
                            ? styles.fab_options_block
                            : styles.fab_options_block_no_ret
                    }>
                    {!altaReten ? (
                        <FAB
                            style={styles.fab_reten}
                            icon="progress-clock"
                            color="white"
                            customSize={50}
                            label="Alta Reten"
                            size="medium"
                            onPress={() => navigation.navigate("AltaRetenScreen")}
                        />
                    ) : null}
                    <FAB
                        style={styles.fab_options}
                        icon="exclamation"
                        color="white"
                        customSize={50}
                        label="Añadir incidencia"
                        size="medium"
                        onPress={() => navigation.navigate("AddIncidenceToday")}
                    />
                    <FAB
                        style={styles.fab_options}
                        icon="calendar"
                        color="white"
                        customSize={50}
                        label="Añadir ausencia"
                        size="medium"
                        onPress={() => navigation.navigate("AddLeave")}
                    />
                    <FAB
                        style={styles.fab_options}
                        icon="clock"
                        label="Añadir parte"
                        customSize={50}
                        color="white"
                        size="medium"
                        onPress={() => navigation.navigate("AddTime", { altaReten: altaReten })}
                    />
                </div>
            ) : null
        }
        <FAB
            icon="plus"
            style={styles.fab}
            color="white"
            onPress={() => setExpanded(!expanded)}
        />
    </>);
};

const styles = StyleSheet.create({
    fab: {
        position: 'absolute', margin: 16, right: 0, bottom: 0, backgroundColor: "green",
    }, fab_options_block: {
        position: "absolute", padding: 5, margin: 0, right: 150, height: 140, width: 80, bottom: 200,
    },
    fab_options_block_no_ret: {
        position: "absolute", padding: 5, margin: 0, right: 150, height: 140, width: 80, bottom: 140,
    },
    fab_options: {
        position: "relative",
        margin: 15,
        width: 200,
        top: 0,
        height: 50,
        padding: -10,
        backgroundColor: "green",
    },
    fab_reten: {
        position: "relative",
        margin: 15,
        width: 200,
        top: 0,
        height: 50,
        padding: -10,
        backgroundColor: "orange",
    }
})

export default HomeScreen;
