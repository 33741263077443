import ApiManager from "./ApiManager";

export const userLogin = async (username, password) => {
    try {
        const response = await ApiManager.request({
            method: 'POST',
            url: '/login',
            data: {
                "params": {username,  password}
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}