import React, { useEffect } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import HomeScreen from "../screens/HomeScreen";
import LeaveScreen from "../screens/LeaveScreen/LeaveScreen";
import AddLeaveScreen from "../screens/LeaveScreen/AddLeaveScreen";
const LeaveStack = createStackNavigator();

function LeaveStackScreen({ navigation }) {



  return (
    <LeaveStack.Navigator
      initialRouteName="AusenciasHome"
      screenOptions={{ cardStyle: { backgroundColor: "#F7F7F7" } }}
    >
      <LeaveStack.Screen
        screenOptions={{ headerShown: false }}
        options={() => ({
          title: "Ausencias",
        })}
        name="AusenciasHome"
        component={LeaveScreen}
      />
      <LeaveStack.Screen
        options={() => ({
          title: "Añadir Ausencia",
        })}
        name="AddLeave"
        component={AddLeaveScreen}
      />
    </LeaveStack.Navigator>
  );
}

export default LeaveStackScreen;
