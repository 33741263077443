import React, { useState } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { Button } from "@react-native-material/core";
import styles from "../TimeScreens/AddTimeScreenStyles"
import CalendarPicker from "react-native-calendar-picker";
import { set } from "react-native-reanimated";
import ResultDay from "./ResultDay";

const SearchDay = ({ navigation }) => {
    const [date, setDate] = useState(new Date());
    const [daySelected, setDaySelected] = useState(false);

    const onDateChange = (date, type) => {
        //function to handle the date change
        if (type === "END_DATE") {
            setDate(date);
        } else {
            setDate(date);
        }
        setDaySelected(true);
    };

    return (
        <View style={[styles.form]} >
            <Text style={styles.formTitle}>Selecciona un dia:</Text>
            <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'column', marginTop: 20 }}>
                <>
                    <CalendarPicker
                        startFromMonday={true}
                        allowRangeSelection={false}
                        minDate={new Date(2018, 1, 1)}
                        maxDate={new Date(2050, 6, 3)}
                        weekdays={["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"]}
                        months={[
                            "Enero",
                            "Febrero",
                            "Marzo",
                            "Abril",
                            "Mayo",
                            "Junio",
                            "Julio",
                            "Agosto",
                            "Septiembre",
                            "Octubre",
                            "Noviembre",
                            "Diciembre",
                        ]}
                        previousTitle="Anterior"
                        nextTitle="Siguiente"
                        selectYearTitle="Selecciona un año"
                        selectMonthTitle="Selecciona un mes del "
                        todayBackgroundColor="#e6ffe6"
                        selectedDayColor="#66ff33"
                        selectedDayTextColor="#000000"
                        scaleFactor={400}
                        textStyle={{
                            fontFamily: "Cochin",
                            color: "#000000",
                        }}
                        onDateChange={onDateChange}
                    />
                </>

                <View style={styles.buttonContainer}>
                    <Button
                        color="green"
                        style={styles.button}
                        onPress={() => {
                            if (daySelected) {
                                navigation.navigate("ResultDay", { date: date });
                            }else{
                                alert("Selecciona un dia");
                            }
                        }}
                        title="Buscar"
                    />

                </View>
            </View>
        </View>
    );
}

export default SearchDay;